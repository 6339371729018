import React from "react";
import {connect} from "react-redux";
import { Form, Button } from 'antd';
import {FormattedMessage} from "react-intl";

function ForgotPassResend() {
    return (
        <div className="form-block resent">
            <h2> <FormattedMessage id='email_has_been_sent' /></h2>
            <p>
                <FormattedMessage id='please_check_email_text_info' />
            </p>
            <div >
                <Form
                    name="normal_login"
                    layout="vertical"
                    className="login-form"
                    initialValues={{ remember: true }}
                    requiredMark={false}
                    // onFinish={loginUser}
                    >
                    
                    <Form.Item className="">
                        <div className="login-link">
                            <span><FormattedMessage id="did_not_receive_the_link"/></span>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            <FormattedMessage id='resend'/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassResend);