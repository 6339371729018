import React from 'react'
import './style.sass';

function LoadingPage() {

    return (
        <div className="loading-page">
            <div className="header">
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className="display-content">
                <div className="left-bar">
                    {[1,2,3,4,5].map(i => (
                        <div key={i}>
                            <div></div>
                            <div style={{width: (80 - i * 10)}}></div>
                        </div>
                    ))}
                    
                </div>
                <div className="content">
                    <div>
                        <div></div>
                        <div>
                            <div className="picture"></div>
                            <div className="cart-name"></div>
                            <div className="cart-lastname"></div>
                            <div className="contact">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default LoadingPage;