import React from "react";
import {connect} from "react-redux";
import ForgotPassResend from "./forgotPassResend";
import ForgotPassForm from "./forgotPassForm";

function ForgotPassword({showResendForgotPassword}) {
    return (
        <>
            {showResendForgotPassword? <ForgotPassResend /> : <ForgotPassForm />}
        </>
    )
}

function mapStateToProps(state) {
    return {
        showResendForgotPassword: state.loginPageState.showResendForgotPassword
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);