import initialStore from "../initialStore";
// import {} from "@actions/actionTypes";

export default function settingsReducer(state = initialStore.settings, action) {
    switch (action.type) {
        
        default:
            return state;
    }
}
