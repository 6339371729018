import React from "react";



export default function ({expandIcon, props}) {
    return (
        <span {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20.4" height="20.4" viewBox="0 0 20.4 20.4">
                <g id="icon_outlined_editor_edit_clip_group_" data-name="icon/outlined/editor/edit (clip group)" transform="translate(0.2 0.2)" opacity="0.999">
                    <rect id="icon_outlined_editor_edit_style_" data-name="icon/outlined/editor/edit (style)" width="20" height="20" fill="none"/>
                    <path id="Vector" d="M18.858,20H.785A.793.793,0,0,1,0,19.2V.8A.793.793,0,0,1,.785,0H18.86a.793.793,0,0,1,.786.8v2.61a.2.2,0,0,1-.2.2H18.072a.2.2,0,0,1-.2-.2V1.8H1.767V18.2H17.873v-1.61a.2.2,0,0,1,.2-.2h1.376a.2.2,0,0,1,.2.2V19.2A.793.793,0,0,1,18.858,20Zm-2.691-6.873a.209.209,0,0,1-.207-.21v-1.98H7.929a.206.206,0,0,1-.2-.208V9.271a.207.207,0,0,1,.2-.209H15.96V7.083a.208.208,0,0,1,.206-.21.2.2,0,0,1,.127.046l3.629,2.917a.2.2,0,0,1,.058.073.211.211,0,0,1,0,.182.2.2,0,0,1-.058.073l-3.629,2.917A.2.2,0,0,1,16.167,13.127Z" fill="currentColor" stroke="#f8f8fa" strokeWidth="0.4"/>
                </g>
            </svg>
        </span>
    )
}