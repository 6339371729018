import React, {useState} from "react";
import {connect} from "react-redux";
import { Form, Input, Button } from 'antd';
import {FormattedMessage} from "react-intl";
import ValidPasswordPopup from "@components/validPasswordPopup"


function NewPassword() {

    const [isFocusOnPassword, setIsFocusOnPassword] = useState(false);
    const [isFocusOnConfirmPassword, setIsFocusOnConfirmPassword] = useState(false);
    const [passwords, setPassword] = useState({password: '', confirm_password: ''});

    const changePassword = (e) => {
        setPassword({password: e.password, confirm_password: e.confirm_password});
    }

    const changeInput = (e) => {
        setPassword({...passwords, [e.target.name]: e.target.value || ''});
    }

    return (
        <div className="form-block">
            <h2> <FormattedMessage id='create_new_password' /></h2>
            <div >
                <Form
                    name="normal_login"
                    layout="vertical"
                    className="login-form"
                    initialValues={{ remember: true }}
                    requiredMark={false}
                    onFinish={changePassword}
                    >
                    <ValidPasswordPopup open={isFocusOnPassword} inputText={passwords.password}>
                        <Form.Item
                            name="password"
                            label={<FormattedMessage id='password'/>}
                            rules={[{ required: true, message: 'Please input your Password!' }]} >
                            <Input.Password
                                name='password'
                                onChange={changeInput}
                                onFocus={() => setIsFocusOnPassword(true)} 
                                onBlur={() => setIsFocusOnPassword(false)}/>
                        </Form.Item>
                    </ValidPasswordPopup>
                    <ValidPasswordPopup open={isFocusOnConfirmPassword} inputText={passwords.confirm_password}>
                        <Form.Item
                            name="confirm_password"
                            label={<FormattedMessage id='confirm_password'/>}
                            rules={[{ required: true, message: 'Please input your Password!' }]} >
                            <Input.Password
                                name='confirm_password'
                                onChange={changeInput}
                                onFocus={() => setIsFocusOnConfirmPassword(true)}
                                onBlur={() => setIsFocusOnConfirmPassword(false)}/>
                        </Form.Item>
                    </ValidPasswordPopup>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            <FormattedMessage id='reset_password'/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);