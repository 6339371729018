import {
    REQUEST_BUYIN_CSV_DOWNLOADER_URL,
    REQUEST_BUYIN_LIST,
    REQUEST_BUYIN_LIST_FILTER,
    REQUEST_CANCEL_BUYIN_CSV_DOWNLOAD,
} from "@actions/actionTypes";

export function getBuyinList(params) {
    return dispatch => {
        dispatch({
            type: REQUEST_BUYIN_LIST,
            params
        })
    }
}

export function getBuyinListFilter() {
    return dispatch => {
        dispatch({
            type: REQUEST_BUYIN_LIST_FILTER
        })
    }
}

export function getBuyinsCsvLink(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_BUYIN_CSV_DOWNLOADER_URL,
            data
        })
    }
}

export function cancelBuyinCsvDownload() {
    return dispatch => {
        dispatch({
            type: REQUEST_CANCEL_BUYIN_CSV_DOWNLOAD
        })
    }
}
