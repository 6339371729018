import initialStore from "../../initialStore";
import {
    SET_CLIENT_LIST,
    SET_EDIT_CLIENT,
    SET_ADD_CLIENT
} from "@actions/actionTypes";

export default function clientsListReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_CLIENT_LIST:
            return  action.clients
        case SET_EDIT_CLIENT:
        case SET_ADD_CLIENT:
            let newClients = state.items.map(item => {
                if (item.id === action.client.id) {
                    return action.client;
                }
                return item;
            });
            return {
                total: state.total,
                items: newClients
            };
        default:
            return state;
    }
}
