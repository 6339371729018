import LudoClassic from "@assets/img/icon/switch_to/ludo_classic.svg";
import ClubRummy from "@assets/img/icon/switch_to/club_rummy.svg";
import PopTheBall from "@assets/img/icon/switch_to/pop_the_ball.svg";
import BinaryOption from "@assets/img/icon/switch_to/binary_option.svg";
import BonusSystem from "@assets/img/icon/switch_to/bonus_system.svg";
import TemboClient from "@assets/img/icon/switch_to/tembo.svg";
import Slots from "@assets/img/icon/switch_to/slots.svg";
import CoreIcon from "@assets/img/icon/switch_to/logo_core.svg";

export default {
    CORE: {
        "id":"e84e907b-fbbd-44b1-8f21-7fc49339c9d0",
        "name":"Core",
        "url": process.env.CORE_URL,
        "icon": CoreIcon
    },
    LUDO: {
        "id":"c80e74e3-01cb-4ac4-8f2f-b88c4c9af890",
        "name":"Ludo",
        "url": process.env.LUDO_URL,
        "icon": LudoClassic
    },
    POP_THE_BALL: {
        "id":"d94e2e3c-9260-4619-9a64-8242246ba2d5",
        "name":"Pop The Ball",
        "url": process.env.POP_THE_BALL_URL,
        "icon": PopTheBall
    },
    TEEN_PATTI: {
        "id":"17f8d045-39f2-40f6-bfaa-2085e550543a",
        "name":"TeenPatti",
        "url": process.env.TEEN_PATTI_URL,
        "icon": ClubRummy
    },
    GENIES_WONDER: {
        "id":"719df07d-7c54-41be-ba7d-f7f1c396b868",
        "name":"Genie's Wonder",
        "url": process.env.GENIES_WONDER_URL,
        "icon": Slots
    },
    GENIES: {
        "id":"2b684053-62a1-427c-9929-f5c4df54de5c",
        "name":"Genie's 12 Wishes",
        "url": process.env.GENIES_URL,
        "icon": Slots
    },
    CLUB_RUMMY: {
        "id":"431e431f-3675-4adb-8567-a5c627ee5752",
        "name":"Rummy",
        "url": process.env.CLUB_RUMMY_URL,
        "icon": ClubRummy
    },
    POKER: {
        "id":"d1b5a8b3-ba13-4267-acbc-f51dfe38c3e0",
        "name":"Poker",
        "url": process.env.POKER_URL,
        "icon": ClubRummy
    },
    SNAKE_AND_LADDERS: {
        "id":"9db000dd-53a9-4404-8fc3-fe4f047ec5f4",
        "name":"Snakes And Ladders",
        "url": process.env.SNAKE_AND_LADDERS_URL,
        "icon": LudoClassic
    },
    BINARY_OPTION: {
        "id":"f0ffdd4c-9a21-4d02-b753-a4c4de9d0b61",
        "name":"Binary Options",
        "url": process.env.BINARY_OPTION_URL,
        "icon": BinaryOption
    },
    BONUS_SYSTEM: {
        "id":"5f5ce70a-d2b7-4729-b16c-48e340d9440c",
        "name":"Bonus",
        "url": process.env.BONUS_SYSTEM_URL,
        "icon": BonusSystem
    },
    TEMBO_CLIENT: {
        "id":"94b2673b-f1fb-4b8d-ac52-e03a792e79bb",
        "name":"Tembo",
        "url": process.env.TEMBO_CLIENT,
        "icon": TemboClient
    }
}