import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import {Table, Pagination} from "antd";
import {getBuyinList} from "@actions/buyinsActions";
import moment from "moment-timezone";
import classNames from "classnames";
import BuyinsFilter from "@components/BuyinsFilter/BuyinsFilter";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {TableProfit} from "@components/TableProfit/TableProfit";
import {copyLabel} from "@services/TableServices";


function Buyin({intl, loading, getBuyinList, buyins, setDoneMsg, setErrorMsg}) {
    let history = useHistory()
    let search = history && history.location && history.location.search

    const [totalPages, setTotalPages] = useState(0)
    const [limit, setLimit] = useState(+getUrlValue(search).limit || 10)
    const [page, setPage] = useState(+getUrlValue(search).page || 1)
    const [profit, setProfit] = useState({})
    const [showFilter, setShowFilter] = useState(false);

    const columns = [
        {
            title: intl.formatMessage({id: "client"}),
            dataIndex: 'client',
            width: "fit-content"
        },
        {
            title: intl.formatMessage({id: "player"}),
            dataIndex: 'player',
            width: "fit-content",
        },
        {
            title: intl.formatMessage({id: "game_id"}),
            dataIndex: 'gameId',
            width: "fit-content",
            render: (text, record) => {
                return <div className="copyable" onDoubleClick={(e) => {
                    copyLabel(e, record.gameId)
                        .then(() => {
                            setDoneMsg("text_is_copied")
                        }).catch(() => {
                        setErrorMsg("something_wrong_the_text_is_not_copied")
                    })
                }}>{record.gameId}</div>
            }
        },
        {
            title: intl.formatMessage({id: "session"}),
            dataIndex: 'session',
            width: "fit-content",
            render: (text, record) => {
                return <div className="copyable" onDoubleClick={(e) => {
                    copyLabel(e, record.session)
                        .then(() => {
                            setDoneMsg("text_is_copied")
                        }).catch(() => {
                        setErrorMsg("something_wrong_the_text_is_not_copied")
                    })
                }}>{record.session}</div>
            }
        },
        // {
        //     title: intl.formatMessage({id: "date"}),
        //     dataIndex: 'created',
        //     width: "fit-content",
        //     render: (text, record) => {
        //         const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //         return <div>{moment(record.created).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}</div>
        //     }

        // },
        {
            title: intl.formatMessage({id: "amount"}),
            dataIndex: 'amount',
            width: "fit-content"
        },
        {
            title: intl.formatMessage({id: "result"}),
            dataIndex: 'result',
            width: "fit-content"
        },

        {
            render: () => ""
        }
    ];

    const fetchBuyins = (isFirst) => {
        let initialForm = getUrlValue(search)
        initialForm = {
            ...initialForm,
            page: initialForm.page || page,
            limit: initialForm.limit || limit,
            playerName: initialForm.playerName || ""
        }
        if (Object.keys(initialForm).length) {
            getBuyinList(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        fetchBuyins(true)
    }, [])

    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search
            if (loc) {
                const initialForm = getUrlValue(loc)
                getBuyinList(initialForm)
                setPage(+initialForm.page || page)
                setLimit(+initialForm.limit || limit)
            }
        })
    }, [history])

    useEffect(() => {
        if (buyins && buyins.items) {
            setTotalPages(buyins.total);
            setProfit(buyins.profit || {});
        }
    }, [buyins]);

    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;
        if (limit === changedLimit) newPage = changedPage
        setPage(newPage);
        setLimit(changedLimit)
        const initialForm = getUrlValue(search)
        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()})
    }


    return (
        <div className="content buyins">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='buy_in'/></div>
                <button className={classNames("filterBtn", {show: showFilter})}
                        onClick={() => setShowFilter(prev => !prev)}>
                    <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button>
            </div>
            <BuyinsFilter limit={limit}
                          setPage={setPage}
                          showFilter={showFilter}
            />
            <div className="buyins">
                <div className="mainTable">
                    {loading._buyin_list ? <TableLoader count={limit} column={12} icon={false} drag={false} actions={0}/> :
                        <div>
                            <Table dataSource={buyins.items || []}
                                   columns={columns}
                                   rowKey="id"
                                   scroll={{x: '100%'}}
                                   pagination={false}
                            />
                            <TableProfit profit={profit}
                                         totalPages={totalPages}
                                         handleTablePaginate={handleTablePaginate}
                                         limit={limit}
                                         page={page}
                                         intl={intl}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        buyins: state.buyinsList,
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    getBuyinList,
    setErrorMsg,
    setDoneMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Buyin));