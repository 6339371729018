import {SET_BUYIN_LIST} from "@actions/actionTypes";

export default function BuyinsListReducer(state = [], action) {
    switch (action.type) {
        case SET_BUYIN_LIST:
            return action.buyins
        default:
            return state;
    }
}
