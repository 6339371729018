import initialStore from "../initialStore";
import { CHANGE_LOADING } from "@actions/actionTypes";

export default function loadingReducer(state = initialStore.emeptyObj, action) {
    switch (action.type) {
        case CHANGE_LOADING:
            return {...state, ...action.loading};
        default:
            return state;
    }
}
