import React from "react";
import {connect} from "react-redux";
import { Form, Input, Button } from 'antd';
import {FormattedMessage} from "react-intl";
import { Link } from 'react-router-dom';

function ForgotPassForm() {
    return (
        <div className="form-block forgot">
            <h2> <FormattedMessage id='forgot_your_password' /></h2>
            <p>
                <FormattedMessage id='forgot_password_instruction_text' />
            </p>
            <div >
                <Form
                    name="normal_login"
                    layout="vertical"
                    className="login-form"
                    initialValues={{ remember: true }}
                    requiredMark={false}
                    // onFinish={loginUser}
                    >
                    <Form.Item
                        name="username"
                        label={<FormattedMessage id='email'/>}
                        rules={[{ required: true, message: <FormattedMessage id='Please input your Email!'/> }]}
                    >
                        <Input  placeholder="Type your email" />
                    </Form.Item>
                    <Form.Item className="">
                        <div className="login-link">
                            <span><FormattedMessage id='remember_password'/></span>
                
                            <Link to="/login">
                                <FormattedMessage id='login'/>
                            </Link>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            <FormattedMessage id='send'/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassForm);