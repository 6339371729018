import {
    REQUEST_CLIENT_LIST,
    REQUEST_ADD_CLIENT,
    REQUEST_EDIT_CLIENT,
    CLIENT_CONTROL_POPUP,
} from "@actions/actionTypes";

export function getClientsList(page, limit, nameLike="", sortDirDesc="") {
    return dispatch => {
        dispatch({
            type: REQUEST_CLIENT_LIST,
            limit,
            page,
            nameLike,
            sortDirDesc
        });
    }
}

export function addClient(body) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_CLIENT,
            body
        });
    }
}

export function editClient(body) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_CLIENT,
            body
        });
    }
}

