import React, {useEffect, useState} from "react";
import {getBetListFilter, getBetsCsvLink, cancelBetCsvDownload} from "@actions/betsActions";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {DatePicker, Form, Input, Select} from "antd";
import {BetStatus, BetTypeArr} from "@constants/Enums";
import {getUrlString, getUrlValue} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {getFormattedForForm, getFormattedBody} from "@services/TableServices";
import moment from "moment-timezone";
import iconDown from "@assets/img/icon/iconDown.svg";
const keysOfDate = ["dateFrom", "dateTo"];
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";

function BetsFilter({filter, intl , limit, setPage, showFilter, getBetListFilter, getBetsCsvLink, loading, cancelBetCsvDownload, allowCancel }) {
    let history = useHistory()
    const [form] = Form.useForm();
    const [clientSelect, setClientSelect] = useState([]);
    const [statusSelect, setStatusSelect] = useState([]);


    useEffect(() => { getBetListFilter() }, []);

    useEffect(() => {
        if (Object.keys(filter).length) {
            setClientSelect(filter.clientSelect || []);
            setStatusSelect(filter.statusSelect);
        }
    }, [filter])

    useEffect(() => {
        return history.listen((location) => {
            const loc = history && history.location && history.location.search
            let initialForm = getUrlValue(loc, keysOfDate);

            if (initialForm.dateFrom || initialForm.dateTo) {
                initialForm.date = [moment(initialForm["dateFrom"]) || "", moment(initialForm["dateTo"]) || ""]
            }
            form.resetFields()
            form.setFieldsValue(getFormattedForForm(initialForm))
        })
    },[history])

    const finishFilter = (data) => {
        const queryObj = {...getFormattedBody(data), page: 1, limit}

        if (data.date && data.date.length) {
            queryObj["dateFrom"] =  data.date[0].toString()
            queryObj["dateTo"] =  data.date[1].toString()
        }
        delete queryObj["date"]

        const {params} = getUrlString(queryObj)
        setPage(1)

        history.push({search: params.toString()})
    }

    const resetFilter = () => {
        const initialObj = {start: "", end: ""}
        form.setFieldsValue({
            clientId:"",
            types: [],
            playerNameLike: "",
            date: initialObj,
            amountTo: "",
            amountFrom: "",
            winTo: "",
            winFrom: "",
            session: "",
        })
    }

    const exportCVS = () => {
        if (loading._bet_csv_downloader_url === undefined || !loading._bet_csv_downloader_url) {
            getBetsCsvLink(form.getFieldsValue())
        }
    }

    return (
        <div className="searchBar csv">
            <Form
                form={form}
                onFinish={finishFilter}
                layout="vertical"
                validateTrigger="onBlur">
                <div className={classNames("searchBar--row",{show: showFilter})}>
                    <div className="searchBar--col">
                        <Form.Item
                            label={<FormattedMessage id={"client"}/>} name="clientId">
                            <Select  getPopupContainer={triggerNode => triggerNode.parentElement} allowClear
                                     suffixIcon={<img src={iconDown} />}>
                                >
                                {clientSelect.map(client => (
                                    <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="playerNameLike"
                            label={<FormattedMessage id={"player_name"}/>}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_player_name"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"bet_date"}/>} name="date">
                            <DatePicker.RangePicker getPopupContainer={triggerNode => triggerNode.parentElement} showTime/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"bet_amount"}/>}>
                            <Form.Item name="amountFrom" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_from_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="amountTo" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                       <Form.Item label={<FormattedMessage id={"types"}/>} name="types">
                           <Select mode="multiple" optionLabelProp="label"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    suffixIcon={<img src={iconDown} />}
                                    showArrow
                                    showSearch={false}>
                                {BetTypeArr.map((type, index)=> (
                                    <Select.Option key={type} value={""+ (index + 1)}
                                                   label={<FormattedMessage id={type}/>}>
                                        <FormattedMessage id={type}/>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"win_amount"}/>}>
                            <Form.Item name="winFrom" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_from_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="winTo" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="session"
                            label={<FormattedMessage id={"session"}/>} required={false}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `value_must_be_GUID`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_session"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col btnBox">
                        <div className="filterPart">
                            <button type="submit" className="miniBtn small">
                                <FormattedMessage id='search'/>
                            </button>
                            <button type="button" className="miniBtn default small" onClick={resetFilter}>
                                <FormattedMessage id='clear'/>
                            </button>
                        </div>
                        <div className="exportPart">
                            <CsvDownloader exportCVS={exportCVS}
                                           downloadLoading={loading._bet_csv_downloader_url}
                                           cancelLoading={loading._cancel_bet_csv_download}
                                           onCancel={cancelBetCsvDownload}
                                           allowCancel={allowCancel}/>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filter: state.betsListFilter,
        csvLink: state.betsCSVLink,
        loading: state.loading,
        allowCancel: state.csvAllowCancel.allowBets
    }
}

const mapDispatchToProps = {
    getBetListFilter,
    getBetsCsvLink,
    cancelBetCsvDownload
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BetsFilter));