import {getBuyinListFilter, getBuyinsCsvLink, cancelBuyinCsvDownload} from "@actions/buyinsActions";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Form, Input, Select} from "antd";
import React, {useEffect, useState} from "react";
import {getUrlString, getUrlValue} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import { getFormattedForForm, getFormattedBody} from "@services/TableServices";
import iconDown from "@assets/img/icon/iconDown.svg";
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";

function BuyinsFilter({filter, intl , limit, setPage, showFilter, getBuyinListFilter, getBuyinsCsvLink,  loading, cancelBuyinCsvDownload, allowCancel}) {
    let history = useHistory()
    const [form] = Form.useForm();
    const [clientSelect, setClientSelect] = useState([]);


    useEffect(() => { getBuyinListFilter() }, []);


    useEffect(() => {
        if (Object.keys(filter).length) {
            setClientSelect(filter.clientSelect || []);
        }
    }, [filter])

    useEffect(() => {
        return history.listen((location) => {
            const loc = history && history.location && history.location.search
            let initialForm =  getUrlValue(loc)

            form.resetFields()
            form.setFieldsValue(getFormattedForForm(initialForm))
        })
    },[history])

    const finishFilter = (data) => {
        const queryObj = {...getFormattedBody(data), page: 1, limit}

        if (data.date && data.date.length) {
            queryObj["dateFrom"] =  data.date[0].toString()
            queryObj["dateTo"] =  data.date[1].toString()
        }
        delete queryObj["date"]

        const {params} = getUrlString(queryObj)
        setPage(1)

        history.push({search: params.toString()})
    }

    const resetFilter = () => {
        let initialForm = {
            clientId:"",
            gameId: "",
            sessionId: "",
            playerName: "",
            amountTo: "",
            amountFrom: "",
            resultTo: "",
            resultFrom: "",
        }

        form.setFieldsValue(initialForm)
    }

    const exportCVS = () => {
        if (loading._buyin_csv_downloader_url === undefined || !loading._buyin_csv_downloader_url) {
            getBuyinsCsvLink(form.getFieldsValue())
        }
    }

    return (
        <div className="searchBar csv">
            <Form
                form={form}
                onFinish={finishFilter}
                layout="vertical"
                validateTrigger="onBlur">
                <div className={classNames("searchBar--row",{show: showFilter})}>
                    <div className="searchBar--col">
                        <Form.Item
                            label={<FormattedMessage id={"client"}/>} name="clientId">
                            <Select  getPopupContainer={triggerNode => triggerNode.parentElement} allowClear
                                     suffixIcon={<img src={iconDown} />}>
                                {clientSelect.map(client => (
                                    <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="gameId"
                            label={<FormattedMessage id={"game_id"}/>}  rules={[
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    const ipRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
                                    if (value && !ipRegExp )
                                    {
                                        return Promise.reject(intl.formatMessage({id: `value_must_be_GUID`}))

                                    }
                                    return Promise.resolve();
                                },
                            })
                        ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_game_id"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="sessionId"
                            label={<FormattedMessage id={"session"}/>} required={false}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `value_must_be_GUID`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_session"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="playerName"
                            label={<FormattedMessage id={"player_name"}/>}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_player_name"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"buyin_amount"}/>}>
                            <Form.Item name="amountFrom" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="amountTo" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `amount_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"buyin_result"}/>}>
                            <Form.Item name="resultFrom" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `result_range_from_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input  autoComplete="off" placeholder={intl.formatMessage({id: "from"})}/>
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="resultTo" noStyle rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^\d+(\.\d+)?$/.test(value)
                                        if (value && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `result_range_to_must_be_number`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                                <Input   autoComplete="off" placeholder={intl.formatMessage({id: "to"})}/>
                            </Form.Item>
                        </Form.Item>
                    </div>
                    
                    {/* <div className="searchBar--col">
                       <Form.Item label={<FormattedMessage id={"types"}/>} name="types">
                           <Select mode="multiple" optionLabelProp="label"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    showArrow
                                    suffixIcon={<img src={iconDown} />}
                                    showSearch={false}>
                                {BuyinTypeArr.map((type, index)=> (
                                    <Select.Option key={type} value={""+(index + 1)}
                                                   label={<FormattedMessage id={type}/>}>
                                        <FormattedMessage id={type}/>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div> */}
                    
                    <div className="searchBar--col btnBox">
                        <div className="filterPart">
                            <button type="submit" className="miniBtn small">
                                <FormattedMessage id='search'/>
                            </button>
                            <button type="button" className="miniBtn default small" onClick={resetFilter}>
                                <FormattedMessage id='clear'/>
                            </button>
                        </div>
                       <div className="exportPart">
                           <CsvDownloader exportCVS={exportCVS}
                                          downloadLoading={loading._buyin_csv_downloader_url}
                                          cancelLoading={loading._cancel_buyin_csv_download}
                                          onCancel={cancelBuyinCsvDownload}
                                          allowCancel={allowCancel}/>
                       </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filter: state.buyinsListFilter,
        loading: state.loading,
        allowCancel: state.csvAllowCancel.allowBuyIn
    }
}

const mapDispatchToProps = {
    getBuyinListFilter,
    getBuyinsCsvLink,
    cancelBuyinCsvDownload
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BuyinsFilter));